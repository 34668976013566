import React, { useRef, useState, useEffect } from "react";
import { useService } from "@app/hooks";
import { TYPES } from "@app/service/types";
import { IUserService, VerificationCodeValidationResult } from '@app/service/users';
import { fmtMsg } from "@app/util/func";
import { Button, Col, Form, Input, Modal, Row, Tooltip, Icon, Popconfirm } from "antd-min";
import { FormComponentProps } from "antd/lib/form";
import { GLForm, LoginIdType, MatIcon, GLUtil } from "gl-commonui";
import { GSAdminLocale } from "@app/locales/localeid";
import "./registration-verification.less";

interface RegistrationVerificationProps {
	emailId?: string;
	phone?: string;
	countryCode?: string;
	verifyPhone: boolean;
	verifyEmail: boolean;
	enablePhoneRegistration?: boolean;
	afterVerify?: (
		emailVerificationData: LoginIdVerificationData,
		phoneVerificationData: LoginIdVerificationData
	) => Promise<void>;
	onBackClick: () => void;
}

export interface LoginIdVerificationData {
	loginIdType: LoginIdType;
	loginId: string;
	verificationCodeId: number;
	verificationCode: string;
}

export const RegistrationVerification = (props: RegistrationVerificationProps) => {
	const { emailId, phone, countryCode, verifyEmail, verifyPhone, enablePhoneRegistration, afterVerify } = props;
	const emailVerificationData = useRef<LoginIdVerificationData>(null);
	const phoneVerificationData = useRef<LoginIdVerificationData>(null);
	const [updateComponent, setUpdateComponent] = useState(false);
	const [submittingData, setSubmittingData] = useState(false);
	const [deletedContact, setDeletedContact] = useState<null | LoginIdType>(null);
	const hasBothEmailAndPhone = emailId && emailId.length && phone && phone.length;
	const onCodeVerificationComplete = (data: LoginIdVerificationData) => {
		const buttonDisabled = isSubmitButtondisabled();
		if (data.loginIdType === LoginIdType.Email) {
			emailVerificationData.current = data;
		} else {
			phoneVerificationData.current = data;
		}
		if (buttonDisabled && !isSubmitButtondisabled()) {
			setUpdateComponent(!updateComponent);
		}
	};

	const isSubmitButtondisabled = () => {
		if (hasBothEmailAndPhone && verifyEmail && verifyPhone && deletedContact === LoginIdType.Email) {
			return !phoneVerificationData.current
		}
		if (hasBothEmailAndPhone && verifyEmail && verifyPhone && deletedContact === LoginIdType.Phone) {
			return !emailVerificationData.current
		}
		if (emailId && emailId.length && verifyEmail && phone && phone.length && verifyPhone) {
			return !emailVerificationData.current || !phoneVerificationData.current;
		}
		if (emailId && emailId.length && verifyEmail) {
			return !emailVerificationData.current;
		}
		if (phone && phone.length && verifyPhone) {
			return !phoneVerificationData.current;
		}
		return false;
	};

	const onBackClick = () => {
		const titleMsg = props.enablePhoneRegistration
							? fmtMsg({ id: GSAdminLocale.RegistrationFormVerifyCfmLeave })
							: fmtMsg({ id: GSAdminLocale.RegistrationVerifyEmailCfm})
		if (emailVerificationData || phoneVerificationData) {
			Modal.confirm({
				title: titleMsg,
				onOk: props.onBackClick,
				okText: fmtMsg({ id: GSAdminLocale.RegistrationFormVerifyLeaveOkTxt }),
				cancelText: fmtMsg({ id: GSAdminLocale.RegistrationFormVerifyLeaveCancelTxt }),
			});
		} else {
			props.onBackClick();
		}
	};

	const onSubmitClick = () => {
		setSubmittingData(true);
		try {
			afterVerify(emailVerificationData.current, phoneVerificationData.current).catch(() => {
				setSubmittingData(false);
			});
		} catch(e){
			setSubmittingData(false);
		}
	};
	const deletedContactEnabled = hasBothEmailAndPhone && typeof deletedContact !== 'number';
	const handleContactDeleted = (loginIdType: LoginIdType) => () => {
		setDeletedContact(loginIdType);
	}
	
	return (
		<div className="reg-ver">
			{hasBothEmailAndPhone && deletedContact === LoginIdType.Email ? null : 
				<RenderVerification
				icon="mail"
				headerText={fmtMsg({ id: GSAdminLocale.RegistrationFormVerifyHeadEmail })}
				loginId={emailId}
				loginIdType={LoginIdType.Email}
				loginIdNotEnteredTxt={fmtMsg({ id: GSAdminLocale.RegistrationFormVerifyEmailNotEnt })}
				verificationInputLabel={fmtMsg({ id: GSAdminLocale.RegistrationFormVerifyEmailLabel })}
				onVerificationCompletion={onCodeVerificationComplete}
				disableVerification={!verifyEmail}
				className={!verifyEmail ? "reg-ver__first" : ""}
				onContactDeleted={handleContactDeleted(LoginIdType.Email)}
				deletedContactEnabled={deletedContactEnabled}
			/>
			}
			{enablePhoneRegistration && 
				hasBothEmailAndPhone && deletedContact === LoginIdType.Phone ? null :
				<RenderVerification
					icon="smartphone"
					headerText={fmtMsg({ id: GSAdminLocale.RegistrationFormVerifyHeadPhone })}
					loginId={phone ? `${countryCode}${phone}` : null}
					loginIdType={LoginIdType.Phone}
					loginIdNotEnteredTxt={fmtMsg({ id: GSAdminLocale.RegistrationFormVerifyPhoneNotEnt })}
					verificationInputLabel={fmtMsg({ id: GSAdminLocale.RegistrationFormVerifyPhoneLabel })}
					onVerificationCompletion={onCodeVerificationComplete}
					disableVerification={!verifyPhone}
					onContactDeleted={handleContactDeleted(LoginIdType.Phone)}
					deletedContactEnabled={deletedContactEnabled}
				/>
			}
			<div className="reg-ver-nav">
				<Button type="primary" className="reg-ver-nav__btn" onClick={onBackClick} disabled={submittingData}>
					{fmtMsg({ id: GSAdminLocale.RegistrationFormVerifyBackBtn })}
				</Button>
				<Tooltip title={isSubmitButtondisabled() 
					? fmtMsg({id: GSAdminLocale.RegistrationFormVerifyDisTooltip}) 
					:  fmtMsg({id: GSAdminLocale.RegistrationFormVerifyEnaTooltip})}>
						<Button
							type="primary"
							className="reg-ver-nav__btn"
							disabled={isSubmitButtondisabled()}
							onClick={onSubmitClick}
							loading={submittingData}
						>
							{fmtMsg({ id: GSAdminLocale.RegistrationFormVerifySubmitBtn })}
						</Button>
				</Tooltip>
			</div>
		</div>
	);
};

interface RenderVerificationProps extends Partial<FormComponentProps> {
	icon: string;
	headerText: string;
	loginId: string | null;
	loginIdType: LoginIdType;
	loginIdNotEnteredTxt: string;
	verificationInputLabel: string;
	disableVerification: boolean;
	className?: string;
	onVerificationCompletion: (data: LoginIdVerificationData) => void;
	onContactDeleted: () => void;
	deletedContactEnabled: boolean;
}

const RenderVerification = GLForm.create<RenderVerificationProps>()((props: RenderVerificationProps) => {
	const {
		icon,
		headerText,
		loginId,
		loginIdType,
		loginIdNotEnteredTxt,
		verificationInputLabel,
		disableVerification,
		className,
		onVerificationCompletion,
		deletedContactEnabled,
		onContactDeleted,
	} = props;
	const [verificationCountingDown, setVerificationCountingDown] = React.useState(0);
	const [verificationCodeId, setVerificationCodeId] = React.useState(-1);
	const [isVerificationCodeSent, setisVerificationCodeSent] = React.useState(false);
	const userService = useService<IUserService>(TYPES.IUserService);
	const [sendingCode, setSendingCode] = useState(false);
	const [verifingCode, setVerifingCode] = useState(false);
	const [codeVerified, setCodeVerified] = useState(false);
	const timers = useRef([]);
	const invitationCode = GLUtil.queryParse().code;
	
	useEffect(() => {
		return () => {
			timers.current.forEach((inter) => {
				clearInterval(inter);
			});
		};
	}, []);

	const sendVerificationCode = () => {
		if (!loginId) {
			return;
		}

		setSendingCode(true);
		userService.getVerificationCodes(loginId, loginIdType, invitationCode).then(
			(value) => {
				setSendingCode(false);
				setVerificationCodeId(value);
			},
			(reason) => {
				setSendingCode(false);
				setVerificationCountingDown(0);
			}
		);

		//update counting down
		setisVerificationCodeSent(true);
		setVerificationCountingDown(60);

		var countingDown = verificationCountingDown || 60;
		let cdInterval = setInterval(() => {
			setVerificationCountingDown(countingDown--);

			if (countingDown <= 0) {
				clearInterval(cdInterval);
				timers.current = timers.current.filter((f) => f !== cdInterval);
				setVerificationCountingDown(0);
			}
		}, 1000);
		timers.current.push(cdInterval);
	};

	const verifyCode = () => {
		props.form.validateFields((error, values) => {
			if (error) return;

			setVerifingCode(true);
			userService
				.verifyVerificationCodes({
					codeId: verificationCodeId,
					code: values.code as string,
					receiver: loginId,
				})
				.then(
					(validationResult: VerificationCodeValidationResult) => {
						setVerifingCode(false);
						if (validationResult === VerificationCodeValidationResult.Valid) {
							setCodeVerified(true);
							onVerificationCompletion({
								loginIdType,
								loginId,
								verificationCodeId,
								verificationCode: values.code,
							});
						} else {
							// set error
							props.form.setFields({
								code: {
									value: values.code,
									errors: [new Error(fmtMsg({ id: GSAdminLocale.RegistrationFormVerifyInvalidCode }))],
								},
							});
						}
					},
					() => {
						setVerifingCode(false);
					}
				);
		});
	};

	const { getFieldDecorator } = props.form;

	return (
		<div className={className}>
			<div className="reg-ver-head">
				<div className="reg-ver-head__left">
					<MatIcon type={icon} />
					{headerText}
				</div>
				<div>{loginId && loginId}</div>
			</div>
			{!disableVerification && (
				<div className="reg-ver-content">
					<div className="reg-ver__body">
						<div className="reg-ver-vc reg-ver-vc--b">
							{fmtMsg({ id: GSAdminLocale.RegistrationFormVerifyStatus })}:
						</div>
						<div className="reg-ver__status">
							{codeVerified && (
								<div className="reg-ver__status-txt">
									{fmtMsg({ id: GSAdminLocale.RegistrationFormVerifyVer })}{" "}
									<span className="reg-ver__status-icon--verified reg-ver-vc">
										<MatIcon type="check" />
									</span>
								</div>
							)}
							{loginId ? (
								!codeVerified ? (
									<>
										<div className="reg-ver__status-txt">
											{fmtMsg({ id: GSAdminLocale.RegistrationFormVerifyUnver })}{" "}
											<span className="reg-ver__status-icon reg-ver-vc">
												<MatIcon type="close" />
											</span>
										</div>
										&nbsp;&nbsp;&nbsp;&nbsp;
										{verificationCountingDown === 0 && (
											<Button
												type="primary"
												className="btn-verification-code"
												onClick={sendVerificationCode}
												loading={sendingCode}
											>
												{fmtMsg({id: GSAdminLocale.RegistrationFormGetVerificationCode})}
											</Button>
										)}
										{verificationCountingDown > 0 && (
											<Button
												type="primary"
												className="btn-verification-code"
												disabled={true}
											>
												{fmtMsg(
													{ id: GSAdminLocale.RegistrationFormVerificationCountingDownLabel },
													{ 0: verificationCountingDown }
												)}
											</Button>
										)}
										{deletedContactEnabled ? 
											(
												<Popconfirm
													title={fmtMsg({ id: GSAdminLocale.RegistrationRemoveContactConfirm })}
													onConfirm={onContactDeleted}
													onCancel={() => {}}
													okText={fmtMsg({ id: GSAdminLocale.RegistrationRemoveContactConfirmYes })}
													cancelText={fmtMsg({ id: GSAdminLocale.RegistrationRemoveContactConfirmNo })}
													placement="top"
												>
													<div className="reg-ver__status--delete"><Icon type="delete"/> {fmtMsg({ id: GSAdminLocale.RegistrationRemoveContactDelete })} </div>
												</Popconfirm>
												)
											: null
										}
									</>
								) : (
									""
								)
							) : (
								loginIdNotEnteredTxt
							)}
						</div>			
					</div>
					{isVerificationCodeSent && !codeVerified && (
						<Form layout="vertical">
							<Form.Item label={verificationInputLabel} required>
								<Row gutter={8}>
									<Col span={12}>
										{getFieldDecorator("code", {
											initialValue: "",
											rules: [
												{
													required: true,
													message: fmtMsg({ id: GSAdminLocale.RegistrationFormVerifyCodeReq }),
												},
											],
										})(<Input />)}
									</Col>
									<Col span={12}>
										<Button type="primary" className="btn-verification-code" loading={verifingCode} onClick={verifyCode}>
											{fmtMsg({id: GSAdminLocale.RegistrationFormVerifyVerify})}
										</Button>
									</Col>
								</Row>
							</Form.Item>
						</Form>
					)}
				</div>
			)}
		</div>
	);
});
