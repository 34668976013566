import React, { useRef, useState } from "react";
import { Form, Input, Button } from "antd";
import { GLRegistrationModel, GLRegistrationLocalization } from "./Model";
import { FormComponentProps } from "antd/lib/form";
import { GLLoginIdInput } from "./gl-login-input";
import {
    CountryCode,
    defaultPhoneCountryCodeList,
    LoginId,
    LoginIdType,
    passwordValidationRegex,
    validateLoginId,
} from "gl-commonui";
import { IUserService, UniquenessEmailAndPhoneRequestModel } from '@app/service/users';
import { useService } from "@app/hooks";
import { TYPES } from "@app/util";
import { GSAdminLocale } from "@app/locales/localeid";
import { fmtMsg } from "@app/util/func";

export interface GLRegistrationProps extends FormComponentProps {
    invitationCode: string;
    invitationCodeType: number;
    invitationCodeId: string;
    userName?: string;
    userEnglishName?: string;
    email?: string;
    phone?: string;
    phoneCountryCode?: string;
    registrationLocalization?: GLRegistrationLocalization;
    enablePhoneRegistration?: boolean;
    privacyElement?: JSX.Element;
    disableEmail: boolean;
    disablePhone: boolean;
    onNextClick: (model: GLRegistrationModel) => void;
    isPrivacyPolicyAccepted?: boolean;
    countryCode?: string;
}

const GLUserRegistration: React.FC<GLRegistrationProps> = (props) => {
    const initEmailLoginId = {
        type: LoginIdType.Email,
        email: props.email || "",
    };

    const countryCode = defaultPhoneCountryCodeList.find((f) => f.code === props.phoneCountryCode);

    const initPhoneLoginId = {
        type: LoginIdType.Phone,
        country: props.phoneCountryCode ? (countryCode ? countryCode.counrtryAbbr : "US") : "US",
        countryCode: props.phoneCountryCode || "+1",
        phone: props.phone || "",
    };

    const registrationLocalization = props.registrationLocalization;
    const [confirmDirty, setConfirmDirty] = useState(false);
    const [checkingUniqueness, setCheckingUniqueness] = useState(false);
    const emailOrPhoneError = useRef(false);
    const userService = useService<IUserService>(TYPES.IUserService);

    const compareToFirstPassword = (rule: any, value: any, callback: (error: any) => void) => {
        const { form } = props;
        if (value && value !== form.getFieldValue("password")) {
            callback(registrationLocalization.passwordInconsistent);
        } else {
            callback(undefined);
        }
    };

    const validateToNextPassword = (rule: any, value: any, callback: (error: any) => void) => {
        if (value && !passwordValidationRegex.test(value)) {
            callback(registrationLocalization.passwordInvalid);
            return;
        }

        if (value && confirmDirty) {
            const { form } = props;
            form.validateFields(["confirm"], { force: true });
        }
        callback(undefined);
    };

    const handleConfirmBlur = (e: any) => {
        const { value } = e.target;
        setConfirmDirty(confirmDirty || !!value);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const { form } = props;

        form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                let phoneControlValue = form.getFieldValue("phone");
                let emailControlValue = form.getFieldValue("emailId");
                setCheckingUniqueness(true);
                const params: UniquenessEmailAndPhoneRequestModel = {};
                // check email and phone uniqueness
                if (phoneControlValue && phoneControlValue.phone && phoneControlValue.phone.length) {
                    params.phone = `${phoneControlValue.countryCode}${phoneControlValue.phone}`;
                }
                if (emailControlValue && emailControlValue.email && emailControlValue.email.length) {
                    params.email = emailControlValue.email;
                }

                try {
                    const validationResult = await userService.checkEmailOrPhoneUniqeness(params);
                    let isInvalid = false;
                    if (!validationResult.isValidEmail) {
                        isInvalid = true;
                        props.form.setFields({
                            emailId: {
                                value: emailControlValue,
                                errors: [new Error(fmtMsg({id: GSAdminLocale.RegistrationFormEmailExists}))],
                            },
                        });
                    }

                    if (!validationResult.isValidPhone) {
                        isInvalid = true;
                        props.form.setFields({
                            phone: {
                                value: phoneControlValue,
                                errors: [new Error(fmtMsg({id: GSAdminLocale.RegistrationFormPhoneExists}))],
                            },
                        });
                    }

                    setCheckingUniqueness(false);

                    if (isInvalid) {
                        return;
                    }
                } catch (e) {
                    setCheckingUniqueness(false);
                    return;
                }

                let data: GLRegistrationModel = {
                    invitationCode: props.invitationCode,
                    invitationCodeId: props.invitationCodeId,
                    invitationCodeType: props.invitationCodeType,
                    confirmEmail: true,

                    userName: form.getFieldValue("userName"),
                    englishName: form.getFieldValue("englishName"),
                    password: form.getFieldValue("password"),
                    confirmPassword: form.getFieldValue("confirm"),

                    email:
                        emailControlValue.email &&
                        emailControlValue.email.length
                            ? emailControlValue.email
                            : undefined,
                    phone:
                        phoneControlValue &&
                        phoneControlValue.phone &&
                        phoneControlValue.phone.length
                            ? phoneControlValue.phone
                            : undefined,
                    phoneCountryCode:
                        phoneControlValue &&
                        phoneControlValue.phone &&
                        phoneControlValue.phone.length
                            ? phoneControlValue.countryCode
                            : undefined
                };

                props.onNextClick(data);
            }
        });
    };

    const onLoginIdChanged = (value: LoginId) => {
        if (emailOrPhoneError.current) {
            if (value.type === LoginIdType.Email) {
                props.form.setFields({ phone: { errors: undefined, value: props.form.getFieldValue("phone") }, emailId: { value } });
                emailOrPhoneError.current = false;
            } else {
                props.form.setFields({ emailId: { errors: undefined, value: props.form.getFieldValue("emailId") }, phone: { value } });
                emailOrPhoneError.current = false;
            }
        }
    };

    const loginIdsValidate = (rule: any, value: any, callback: (msg?: string) => void) => {
        const { emailId, phone } = props.form.getFieldsValue();

        if (props.enablePhoneRegistration && (!emailId.email || !emailId.email.length) && (!phone.phone || !phone.phone.length)) {
            emailOrPhoneError.current = true;
            callback(fmtMsg({id: GSAdminLocale.RegistrationFormAtLeastOneReq}));
            return;
        }

        if (!props.enablePhoneRegistration && (!emailId.email || !emailId.email.length)) {
            callback(fmtMsg({id: GSAdminLocale.RegistrationFormEmailReq}));
            return;
        }

        const afterCoreValidation = (msg?: string) => {
            if (msg) {
                callback(msg);
                return;
            }
            callback();
            // Further Validations can be done here.
        };

        validateLoginId(registrationLocalization, false)(rule, value, afterCoreValidation);
    };

    const isNextButtonDisabled = () => {
        const { userName, emailId, phone, password, confirm: confirmPassword } = props.form.getFieldsValue();
        const errors = props.form.getFieldsError();
        const hasErrors = Object.keys(errors).some((s) => !!errors[s]);
        if (
            hasErrors ||
            !userName ||
            !userName.length ||
            ((!emailId.email || !emailId.email.length) && (props.enablePhoneRegistration ? !phone.phone || !phone.phone.length : true)) ||
            !password ||
            !password.length ||
            password !== confirmPassword
        ) {
            return true;
        }
        if (props.countryCode === CountryCode.Russsia && !props.isPrivacyPolicyAccepted) {
            return true;
        }
        return false;
    };

    const {
        form: { getFieldDecorator },
    } = props;

    return (
        <div className="registration-container">
            <Form layout="vertical" onSubmit={handleSubmit}>
                <Form.Item label={registrationLocalization.userNameLabel}>
                    {getFieldDecorator("userName", {
                        initialValue: props.userName || "",
                        rules: [{ required: true, message: registrationLocalization.userNameRequired }],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label={registrationLocalization.englishNameLabel}>
                    {getFieldDecorator("englishName", {
                        initialValue: props.userEnglishName || "",
                    })(<Input />)}
                </Form.Item>
                {props.enablePhoneRegistration && 
                    <div>
                        <sup className="registration-container__star">*</sup>{fmtMsg({id: GSAdminLocale.RegistrationFormLoginTip})}
                    </div>
                }
                <Form.Item label={registrationLocalization.emailPhoneLabel}>
                    {getFieldDecorator("emailId", {
                        initialValue: initEmailLoginId,
                        rules: [{ validator: loginIdsValidate }, {required: !props.enablePhoneRegistration}],
                    })(
                        <GLLoginIdInput
                            onChange={onLoginIdChanged}
                            disabled={props.disableEmail || checkingUniqueness}
                            placeholder={registrationLocalization.emailPhonePlaceHolder}
                            loginIdType={LoginIdType.Email}
                        />
                    )}
                </Form.Item>
                {props.enablePhoneRegistration && 
                    <Form.Item label={fmtMsg({id: GSAdminLocale.RegistrationFormPhoneLabel})}>
                        {getFieldDecorator("phone", {
                            initialValue: initPhoneLoginId,
                            rules: [{ validator: loginIdsValidate }],
                        })(
                            <GLLoginIdInput
                                onChange={onLoginIdChanged}
                                disabled={props.disablePhone || checkingUniqueness}
                                hideCountryCode={props.disablePhone}
                                placeholder={fmtMsg({id: GSAdminLocale.RegistrationFormPhonePlaceHolder})}
                                loginIdType={LoginIdType.Phone}
                                forceNumberInput={true}
                            />
                        )}
                    </Form.Item>
                }

                <Form.Item label={registrationLocalization.passwordLabel} hasFeedback>
                    {getFieldDecorator("password", {
                        rules: [
                            { required: true, message: fmtMsg({id: GSAdminLocale.RegistrationFormPassReq}) },
                            { validator: validateToNextPassword },
                        ],
                    })(<Input type="password" autoComplete="new-password" />)}
                </Form.Item>
                <Form.Item label={registrationLocalization.confirmPasswordLabel} hasFeedback>
                    {getFieldDecorator("confirm", {
                        rules: [
                            { required: true, message: registrationLocalization.confirmPasswordRequired },
                            { validator: compareToFirstPassword },
                        ],
                    })(<Input type="password" autoComplete="new-password" onBlur={handleConfirmBlur} />)}
                </Form.Item>
                {props.privacyElement}
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="reg-btn"
                        loading={checkingUniqueness}
                        disabled={isNextButtonDisabled()}
                    >
                        {fmtMsg({id: GSAdminLocale.RegistrationFormBasicStageNext})}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export const GLUserRegistrationForm = Form.create<GLRegistrationProps>()(GLUserRegistration);